import useAxios from "axios-hooks";

const useResetPassword = () => {
  const [
    { loading: resettingPassword, error: resetError, data: resetData },
    resetPassword,
  ] = useAxios(
    {
      url: "/reset-password",
      method: "POST",
    },
    { manual: true },
  );

  const handleResetPassword = async (
    token: string,
    email: string,
    password: string,
    password_confirmation: string,
  ) => {
    try {
      await resetPassword({
        data: {
          token,
          email,
          password,
          password_confirmation,
        },
      });
      return { success: true };
    } catch (error) {
      console.error("Password reset failed:", error);
    }
  };

  return { handleResetPassword, resettingPassword, resetError, resetData };
};

export default useResetPassword;
