import useAxios from "axios-hooks";

const useForgotPassword = () => {
  const [
    { loading: sendingPassword, error: sendError, data: sendData },
    forgotPassword,
  ] = useAxios(
    {
      url: "/forgot-password",
      method: "POST",
    },
    { manual: true },
  );

  const handleForgotPassword = async (email: string) => {
    try {
      await forgotPassword({
        data: {
          email,
        },
      });
      return { success: true };
    } catch (error) {
      console.error("Password send failed:", error);
    }
  };

  return { handleForgotPassword, sendingPassword, sendError, sendData };
};

export default useForgotPassword;
