import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useParams, useSearchParams, useNavigate } from "react-router";
import LoginWave from "../../components/Icons/LoginWave/LoginWave";
import RootUnauthScreen from "../RootScreen/RootUnauthScreen";
import DropShadowBox from "../../components/DropShadowBox/DropShadowBox";
import ProvideButton from "../../components/Buttons/ProvideButton";
import DisclaimerText from "../../components/DisclamerText/DisclamerText";
import { AnimatedBounceContainer } from "../../styled/AnimatedBounceContainer.styled";
import CheckmarkCircle from "../../components/Icons/CheckmarkCircle/CheckmarkCircle";
import RedCrossIcon from "../../components/Icons/RedCrossIcon/RedCrossIcon";
import useResetPassword from "../../hooks/useResetPassword";
import useValidateInput from "../../hooks/useValidateInput";
import ForgotPasswordIcon from "../../components/Icons/ForgotPasswordIcon/ForgotPasswordIcon";

const ResetPasswordScreen: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { handleResetPassword, resettingPassword, resetError, resetData } =
    useResetPassword();
  const { errors, validatePassword } = useValidateInput();

  const { token } = useParams<{ token: string }>();
  const email = searchParams.get("email");

  const [passwords, setPasswords] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (passwords.newPassword !== "") {
      validatePassword(
        "password",
        passwords.newPassword,
        passwords.confirmPassword,
      );
      validatePassword(
        "confirmPassword",
        passwords.confirmPassword,
        passwords.newPassword,
      );
    }
  }, [passwords.newPassword, passwords.confirmPassword]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPasswords((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const submit = () => {
    if (token && email && passwords.newPassword && passwords.confirmPassword) {
      handleResetPassword(
        token,
        email,
        passwords.newPassword,
        passwords.confirmPassword,
      );
    }
  };

  const renderContent = () => {
    if (!resettingPassword && !resetData && !resetError) {
      return (
        <>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <ForgotPasswordIcon />
            <Typography variant="h4">Enter a new password</Typography>
          </Box>
          <FormControl fullWidth variant="outlined">
            <TextField
              label="New Password"
              type="password"
              name="newPassword"
              value={passwords.newPassword}
              onChange={handleChange}
            />
            {errors.password?.error && (
              <FormHelperText error>
                {errors.password.helperText}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <TextField
              label="Confirm Password"
              type="password"
              name="confirmPassword"
              value={passwords.confirmPassword}
              onChange={handleChange}
            />
            {errors.confirmPassword?.error && (
              <FormHelperText error>
                {errors.confirmPassword.helperText}
              </FormHelperText>
            )}
          </FormControl>

          <ProvideButton
            variant="contained"
            sx={{ width: 200, mx: "auto" }}
            onClick={submit}
            disabled={
              !!(
                errors.password?.error ||
                errors.confirmPassword?.error ||
                passwords.newPassword === ""
              )
            }
          >
            SUBMIT
          </ProvideButton>
        </>
      );
    }
    if (resettingPassword) {
      return <CircularProgress color="primary" />;
    }

    if (resetData) {
      return (
        <>
          <AnimatedBounceContainer>
            <CheckmarkCircle />
          </AnimatedBounceContainer>
          <Typography textAlign="center">Password Reset Successful</Typography>
          <ProvideButton
            variant="contained"
            onClick={() => navigate("/")}
            sx={{ width: 200 }}
          >
            Login
          </ProvideButton>
        </>
      );
    }

    if (resetError) {
      return (
        <>
          <AnimatedBounceContainer>
            <RedCrossIcon />
          </AnimatedBounceContainer>
          <Typography color="error" textAlign="center">
            {resetError.response?.data.message || "Password reset failed"}
          </Typography>
          <ProvideButton
            variant="contained"
            sx={{ width: 200 }}
            onClick={submit}
          >
            Try Again
          </ProvideButton>
        </>
      );
    }

    return null;
  };

  return (
    <>
      <LoginWave />
      <RootUnauthScreen>
        <Grid container justifyContent="center">
          <Stack spacing={2} sx={{ zIndex: 1 }}>
            <Typography component="h1" variant="h1">
              Reset your password
            </Typography>
            <DropShadowBox>
              <Stack
                spacing={4}
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: 200, maxWidth: 480 }}
              >
                {renderContent()}
              </Stack>
            </DropShadowBox>
            <DisclaimerText />
          </Stack>
        </Grid>
      </RootUnauthScreen>
    </>
  );
};

export default ResetPasswordScreen;
