import React from "react";
import { Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import LoginWave from "../../components/Icons/LoginWave/LoginWave";
import LoginComponent from "../../components/LoginComponent/LoginComponent";
import { Link } from "react-router";
import DisclaimerText from "../../components/DisclamerText/DisclamerText";
import RootUnauthScreen from "../RootScreen/RootUnauthScreen";

const LoginScreen: React.FC = () => {
  return (
    <>
      <LoginWave />
      <RootUnauthScreen>
        <Grid container justifyContent="center">
          <Stack
            spacing={5}
            sx={{
              width: {
                xs: "100%",
                md: 540,
                zIndex: 1,
              },
            }}
          >
            <Typography component="h1" variant="h1">
              Log in to Provide
            </Typography>
            <Grid container justifyContent="center">
              <LoginComponent />
            </Grid>
            <Typography
              component="span"
              sx={{
                color: "gray.dark",
                lineHeight: 1,
                pl: { xs: 0, md: 4 },
                gap: 1,
              }}
            >
              Don’t have an account?{" "}
              <Link
                to="./sign-up"
                style={{
                  fontWeight: "bold",
                  textDecoration: "none",
                  color: "black",
                }}
              >
                Create a free account now
              </Link>
            </Typography>
            <DisclaimerText />
          </Stack>
        </Grid>
      </RootUnauthScreen>
    </>
  );
};

export default LoginScreen;
