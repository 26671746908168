import { PaletteOptions } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    gray: {
      light: string;
      medium: string;
      dark: string;
    };
    accent: {
      yellow: string;
      green: string;
      cyan: string;
    };
    lightPurple: string;
    lightBlue: string;
    displayGreen: string;
    displayRed: string;
    softRed: string;
    midGray: string;
    border: string;
    hint: string;
  }

  interface PaletteOptions {
    gray: {
      light: string;
      medium: string;
      dark: string;
    };
    accent: {
      yellow: string;
      green: string;
      cyan: string;
    };
    lightPurple: string;
    lightBlue: string;
    displayGreen: string;
    displayRed: string;
    softRed: string;
    midGray: string;
    border: string;
    hint: string;
  }
}

const palette: PaletteOptions = {
  primary: {
    light: "#E1F5FC",
    main: "#8E8ED1",
    dark: "#007865",
  },
  secondary: {
    light: "#E4E3F6",
    main: "#8E8ED1",
    dark: "#BBBAE8",
  },
  background: {
    default: "#f9f9f9",
    paper: "#ffffff",
  },
  text: {
    primary: "#333333",
    secondary: "#666666",
    disabled: "#999999",
  },
  gray: {
    light: "#dddddd",
    medium: "#bbbbbb",
    dark: "#A9A9A9",
  },
  accent: {
    yellow: "#faea26",
    green: "#A6F1CF",
    cyan: "#DBDBDB",
  },
  midGray: "#cccccc",
  lightPurple: "#e0def8",
  lightBlue: "#54B9CC",
  displayGreen: "#2EC4B5",
  displayRed: "#F75C5C",
  softRed: "#FF245E",
  border: "#93929B",
  hint: "#D3D3D3",
};

export default palette;
