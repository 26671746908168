import React from "react";
import { Outlet, useMatch } from "react-router";
import { AppBar, Typography, useTheme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ProvideLogoText from "../Icons/ProvideLogo/ProvideLogoText";
import { loginData, pillData } from "./data/menuData";
import ProvideLogo from "../Icons/ProvideLogo/ProvideLogo";
import PillNavigation from "../PillNavigation/PillNavigation";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { RootState } from "../../store/store";
import { setUserType } from "../../store/slices/signupSlice";

const LoginSignupToolbar: React.FC = () => {
  const theme = useTheme();
  const isSignUp = useMatch("/sign-up");
  const isVerifyEmail = useMatch("/verify-email/*");
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const userType = useAppSelector((state: RootState) => state.signup.userType);
  const dispatch = useAppDispatch();

  function handlePillClick(option: string): void {
    dispatch(setUserType(option));
  }

  return (
    <>
      <AppBar>
        <Grid
          container
          alignItems="center"
          spacing={!isSignUp ? { md: 2.5, lg: 10 } : 3}
          px={2}
          py={{ xs: 2.5, md: 5 }}
        >
          {isDesktop ? (
            <ProvideLogoText width={212} />
          ) : (
            <ProvideLogo width={30} />
          )}

          {!isSignUp && !isVerifyEmail
            ? isDesktop && (
                <Grid container spacing={{ md: 2.5, lg: 10 }}>
                  {loginData.map((item) => (
                    <Typography
                      key={item.id}
                      component="h2"
                      variant={item.variant}
                    >
                      {item.text}
                    </Typography>
                  ))}
                </Grid>
              )
            : isSignUp && (
                <PillNavigation
                  reduxSelector={userType}
                  pillActiveColor="purple"
                  buttons={pillData}
                  onClick={handlePillClick}
                />
              )}
        </Grid>
      </AppBar>
      <Outlet />
    </>
  );
};

export default LoginSignupToolbar;
