import useAxios from "axios-hooks";

const useAuthenticateOnce = () => {
  const [
    {
      loading: authenticating,
      error: authenticateError,
      data: authenticateData,
    },
    authenticateOnce,
  ] = useAxios(
    {
      url: "/authenticate-once",
      method: "GET",
    },
    { manual: true },
  );

  const handleAuthenticateOnce = async (id: string, token: string) => {
    try {
      await authenticateOnce({
        url: `/authenticate-once/${id}?token=${token}`,
      });
      return { success: true };
    } catch (error) {
      console.error("Authentication failed:", error);
    }
  };

  return {
    handleAuthenticateOnce,
    authenticating,
    authenticateError,
    authenticateData,
  };
};

export default useAuthenticateOnce;
