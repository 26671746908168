import React, { useEffect } from "react";
import RootAuthScreen from "../RootScreen/RootAuthScreen";
import { Chip, Stack, Typography } from "@mui/material";
import PillNavigation from "../../components/PillNavigation/PillNavigation";
import { settingsPillData } from "./data/settingsData";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import {
  checkUrlForSettingsOption,
  setSettingsOption,
} from "../../store/slices/settingsSlice";
import { RootState } from "../../store/store";
import UserTopNavigation from "../../components/UserTopNavigation/UserTopNavigation";
import UserSettingsMain from "../../components/UserSettings/UserSettingsMain";
import UserSettingsTeams from "../../components/UserSettings/UserSettingsTeams";
import UserSettingsNotifications from "../../components/UserSettings/UserSettingsNotifications";

const UserSettingsScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const settingsOption = useAppSelector(
    (state: RootState) => state.settings.settingsOption,
  );
  useEffect(() => {
    dispatch(checkUrlForSettingsOption());
  }, [dispatch]);

  function handlePillClick(option: string): void {
    dispatch(setSettingsOption(option));
  }

  return (
    <RootAuthScreen>
      <Stack spacing={4}>
        <UserTopNavigation />
        <Typography component="h1" variant="h1" color="secondary.main">
          My User Settings <Chip variant="outlined" label="Broker" />
        </Typography>
        <PillNavigation
          reduxSelector={settingsOption}
          pillActiveColor="black"
          buttons={settingsPillData}
          onClick={handlePillClick}
        />

        {settingsOption === "main" ? (
          <UserSettingsMain />
        ) : settingsOption === "teams" ? (
          <UserSettingsTeams />
        ) : settingsOption === "notifications" ? (
          <UserSettingsNotifications />
        ) : null}
      </Stack>
    </RootAuthScreen>
  );
};

export default UserSettingsScreen;
