import axios from "axios";
import { getCSRFTokenIfNeeded, getFrontEndUrl } from "./test-utils";

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;
axios.defaults.headers.post["Content-Type"] = "application/json";

export const initializeAxios = async () => {
  axios.defaults.baseURL = await getFrontEndUrl();
  await getCSRFTokenIfNeeded();
};

export const getBaseUrl = async () => {
  const configuration = localStorage.getItem("PF-CONF");
  if (null === configuration) {
    return axios.defaults.baseURL;
  }
  const jsonConfiguration = JSON.parse(configuration);
  return jsonConfiguration.frontend_url;
};

axios.interceptors.request.use(
  async (config) => {
    config.baseURL = await getBaseUrl();
    return config;
  },
  (error) => Promise.reject(error),
);
export default axios;
