import React from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Chip } from "@mui/material";
import dayjs from "dayjs";
import NotificationAvatar from "../../../components/NotificationAvatar/NotificationAvatar";

interface UserRow {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  roles: string[];
  created_at: string;
}

export const columns: GridColDef<UserRow>[] = [
  {
    field: "applicant",
    headerName: "Applicant",
    width: 180,
    valueGetter: (_, row) => `${row.first_name || ""} ${row.last_name || ""}`,
  },
  {
    field: "roles",
    headerName: "Roles",
    width: 150,
    valueGetter: (_, row) =>
      row.roles
        .map((role) =>
          role
            .split("-")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
        )
        .join(", "),
    renderCell: (params: GridRenderCellParams) =>
      params.value ? (
        <Chip
          label={params.value}
          variant="outlined"
          sx={{
            backgroundColor: "white",
            height: 28,
          }}
        />
      ) : null,
  },
  {
    field: "account",
    headerName: "Account",
    width: 120,
    valueGetter: () => "Yellow Stone",
  },
  {
    field: "team",
    headerName: "Team",
    valueGetter: () => "Team A",
  },
  { field: "email", headerName: "Contact Email", width: 280 },
  {
    field: "status",
    headerName: "Status",
    valueGetter: () => "Active",
    renderCell: (params: GridRenderCellParams) =>
      params.value ? (
        <Chip
          label={params.value}
          sx={{
            color: "white",
            backgroundColor: "lightBlue",
            height: 28,
          }}
        />
      ) : null,
  },
  {
    field: "enquiries",
    headerName: "Live Enquiries",
    valueGetter: () => "1",
    renderCell: () => <NotificationAvatar value="1" />,
  },
  {
    field: "created_at",
    headerName: "Created At",
    width: 200,
    renderCell: (params) => {
      const formattedDate = dayjs(params.value).format("h:mma Do MMM YYYY");
      return <span>{formattedDate}</span>;
    },
  },
];
