import React from "react";

const ForgotPasswordIcon: React.FC = () => {
  return (
    <svg
      width="91"
      height="90"
      viewBox="0 0 91 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1610_3431)">
        <path
          d="M58.7373 67.5703C69.3386 67.5703 77.9326 59.0707 77.9326 48.5859C77.9326 38.1012 69.3386 29.6016 58.7373 29.6016C48.136 29.6016 39.542 38.1012 39.542 48.5859C39.542 59.0707 48.136 67.5703 58.7373 67.5703Z"
          fill="#E0DEF8"
        />
        <path
          d="M42.443 9.95625H11.375C10.2437 9.95625 9.15871 10.4007 8.35875 11.1919C7.55879 11.9831 7.10938 13.0561 7.10938 14.175V75.9375C7.10938 77.0564 7.55879 78.1294 8.35875 78.9206C9.15871 79.7118 10.2437 80.1562 11.375 80.1562H79.625C80.7563 80.1562 81.8413 79.7118 82.6413 78.9206C83.4412 78.1294 83.8906 77.0564 83.8906 75.9375V14.0625C83.8906 12.9436 83.4412 11.8706 82.6413 11.0794C81.8413 10.2882 80.7563 9.84375 79.625 9.84375H37.2105"
          stroke="#0F0D21"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.09082 22.1906H39.5427"
          stroke="#0F0D21"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.0117 22.1906H83.4636"
          stroke="#0F0D21"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M67.8096 71.9859H23.7314C22.5184 71.9878 21.3169 71.7531 20.1957 71.2953C19.0745 70.8374 18.0555 70.1655 17.1971 69.3178C16.3387 68.4701 15.6577 67.4634 15.1931 66.3552C14.7284 65.247 14.4893 64.0591 14.4893 62.8594C14.4893 60.4351 15.463 58.1102 17.1962 56.396C18.9295 54.6818 21.2803 53.7188 23.7314 53.7188H67.8096C70.2608 53.7188 72.6115 54.6818 74.3448 56.396C76.078 58.1102 77.0518 60.4351 77.0518 62.8594C77.0518 64.0591 76.8126 65.247 76.348 66.3552C75.8833 67.4634 75.2023 68.4701 74.3439 69.3178C73.4855 70.1655 72.4666 70.8374 71.3453 71.2953C70.2241 71.7531 69.0226 71.9878 67.8096 71.9859Z"
          stroke="#0F0D21"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.8018 59.6109L30.3708 66.1078"
          stroke="#0F0D21"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.8018 66.1078L30.3708 59.6109"
          stroke="#0F0D21"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.7695 59.6109L43.3386 66.1078"
          stroke="#0F0D21"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.7695 66.1078L43.3386 59.6109"
          stroke="#0F0D21"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.7227 59.6109L56.2917 66.1078"
          stroke="#0F0D21"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.7227 66.1078L56.2917 59.6109"
          stroke="#0F0D21"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.6904 59.6109L69.2595 66.1078"
          stroke="#0F0D21"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M62.6904 66.1078L69.2595 59.6109"
          stroke="#0F0D21"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M56.1638 26.4937H35.376V47.0531H56.1638V26.4937Z"
          stroke="#0F0D21"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.7705 38.8406V41.9203"
          stroke="#0F0D21"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.5421 26.4938V23.4141C39.5267 22.601 39.6772 21.7932 39.9847 21.039C40.2922 20.2848 40.7504 19.5997 41.3318 19.0247C41.9132 18.4497 42.6059 17.9966 43.3685 17.6924C44.1311 17.3883 44.9478 17.2394 45.7699 17.2547C46.5926 17.2395 47.4101 17.3883 48.1734 17.6923C48.9367 17.9962 49.6304 18.4491 50.2129 19.024C50.7954 19.5988 51.2549 20.2837 51.564 21.038C51.8731 21.7922 52.0254 22.6003 52.0119 23.4141V26.4938"
          stroke="#0F0D21"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1610_3431">
          <rect width="91" height="90" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ForgotPasswordIcon;
