import React from "react";
import { Avatar, useTheme } from "@mui/material";

interface NotificationAvatarProps {
  value: string | number;
  variant?: "default" | "alert";
}

const NotificationAvatar: React.FC<NotificationAvatarProps> = ({
  value,
  variant = "default",
}) => {
  const theme = useTheme();
  const { notificationAvatar } = theme.custom;

  const getVariantStyles = (variant: string) => {
    switch (variant) {
      case "alert":
        return {
          backgroundColor: "softRed",
          color: "white",
        };
      case "default":
      default:
        return {
          backgroundColor: "gray.light",
          color: "black",
        };
    }
  };

  const { backgroundColor, color } = getVariantStyles(variant);

  return (
    <Avatar
      sx={{
        ...notificationAvatar,
        backgroundColor: backgroundColor,
        color: color,
      }}
    >
      {value}
    </Avatar>
  );
};

export default NotificationAvatar;
