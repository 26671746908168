import {
  Components,
  createTheme,
  CSSInterpolation,
  Theme,
} from "@mui/material/styles";
import palette from "./palette";
import { DataGridProps } from "@mui/x-data-grid";

const provideTheme: Theme = createTheme({ palette });

declare module "@mui/material/styles" {
  interface Components {
    MuiDataGrid?: {
      defaultProps?: Partial<DataGridProps>;
      styleOverrides?: {
        root?: CSSInterpolation;
      };
    };
  }
}

declare module "@mui/material/Switch" {
  interface SwitchPropsVariantOverrides {
    ant: true;
    dashed: true;
  }
}

export const components: Components = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        position: "relative",
        backgroundColor: "white",
        boxShadow: "none",
        borderBottom: `1px solid ${provideTheme.palette.midGray}`,
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        padding: "0 0 0 20px",
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: 27,
        fontFamily: "Roboto",
        fontSize: "1rem",
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        "&.icon-button": {
          color: "black",
          padding: "12px",
          borderRadius: "12px",
          width: "56px",
          height: "32px",
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        width: "100%",
        height: "51px",
        fontSize: "1rem",
        boxShadow: "none",
        borderRadius: 27,
        "&.MuiButton-contained": {
          backgroundColor: "black",
          color: "white",
          "&:disabled": {
            opacity: 0.25,
          },
        },
        "&.MuiButton-outlined": {
          backgroundColor: "white",
          color: "black",
          borderColor: provideTheme.palette.midGray,
          "&:disabled": {
            opacity: 0.5,
          },
        },
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        padding: 0,
        "&.Mui-checked": {
          color: "black",
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: 0,
        color: "black",
        "&.Mui-checked": {
          color: "black",
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      primary: {
        fontFamily: "Roboto",
      },
      secondary: {
        fontFamily: "Roboto",
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      slotProps: {
        inputLabel: {
          shrink: true,
        },
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        backgroundColor: provideTheme.palette.gray.light,
        color: provideTheme.palette.text.disabled,
        border: "none",
        borderRadius: "10px",
        height: "30px",
        fontSize: "0.7rem",
        width: "60px",
        "&.Mui-selected": {
          backgroundColor: "black",
          color: "white",
        },
        "&.Mui-selected:hover": {
          backgroundColor: "black",
          color: "white",
        },
        "&:hover": {
          backgroundColor: provideTheme.palette.gray.medium,
          color: "white",
        },
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        backgroundColor: "black",
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: provideTheme.palette.secondary.dark,
        color: "black",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: 400,
        ml: "20px",
      },
    },
    variants: [
      {
        props: { variant: "filled" },
        style: {
          color: "white",
          backgroundColor: "#FF245E",
          "& .MuiChip-icon": {
            color: "white",
          },
        },
      },
    ],
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        "& .MuiDataGrid-container--top [role='row']": {
          background: "white",
        },
        "& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus": {
          outline: "none",
        },
        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus-within":
          {
            outline: "none",
          },
      },
    },
  },
  MuiSwitch: {
    variants: [
      {
        props: { size: "small" },
        style: {
          width: 44,
          height: 24,
          padding: 0,
          display: "flex",
          "&:active": {
            "& .MuiSwitch-thumb": {
              width: 14,
            },
            "& .MuiSwitch-switchBase.Mui-checked": {
              transform: "translateX(9px)",
            },
          },
          "& .MuiSwitch-switchBase": {
            padding: 5,
            color: provideTheme.palette.text.secondary,
            "&.Mui-checked": {
              transform: "translateX(20px)",
              color: "white",
              "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: provideTheme.palette.primary.main,
              },
            },
          },
          "& .MuiSwitch-thumb": {
            width: 14,
            height: 14,
            borderRadius: 7,
            transition: "width 200ms",
          },
          "& .MuiSwitch-track": {
            borderRadius: 20,
            opacity: 1,
            backgroundColor: provideTheme.palette.accent.cyan,
            boxSizing: "border-box",
          },
        },
      },
    ],
  },
};
