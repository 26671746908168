import React, { useEffect } from "react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  useParams,
  useSearchParams,
  useNavigate,
  useLocation,
} from "react-router";
import LoginWave from "../../components/Icons/LoginWave/LoginWave";
import RootUnauthScreen from "../RootScreen/RootUnauthScreen";
import DropShadowBox from "../../components/DropShadowBox/DropShadowBox";
import ProvideButton from "../../components/Buttons/ProvideButton";
import DisclaimerText from "../../components/DisclamerText/DisclamerText";
import { AnimatedBounceContainer } from "../../styled/AnimatedBounceContainer.styled";
import CheckmarkCircle from "../../components/Icons/CheckmarkCircle/CheckmarkCircle";
import RedCrossIcon from "../../components/Icons/RedCrossIcon/RedCrossIcon";
import useVerifyEmail from "../../hooks/useVerifyEmail";
import useAuthenticateOnce from "../../hooks/useAuthenticateOnce";

const VerifyEmailScreen: React.FC = () => {
  const { id, hash } = useParams<{
    id: string;
    hash: string;
  }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const expires = searchParams.get("expires");
  const signature = searchParams.get("signature");
  const token = searchParams.get("token");
  const isMagicLink = location.pathname.includes("authenticate-once");

  const { handleVerifyEmail, verifyingEmail, verifyError, verifyData } =
    useVerifyEmail();

  const {
    handleAuthenticateOnce,
    authenticating,
    authenticateError,
    authenticateData,
  } = useAuthenticateOnce();

  const verify = () => {
    if (!isMagicLink && id && hash && expires && signature) {
      handleVerifyEmail(id, hash, expires, signature);
    } else if (isMagicLink && id && token) {
      handleAuthenticateOnce(id, token);
    }
  };

  useEffect(() => {
    verify();
  }, []);

  useEffect(() => {
    if (isMagicLink && authenticateData) {
      const timer = setTimeout(() => {
        navigate("/dashboard");
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [authenticateData]);

  const renderContent = () => {
    if (verifyingEmail || authenticating) {
      return <CircularProgress color="primary" />;
    }

    if (verifyData || authenticateData) {
      return (
        <>
          <AnimatedBounceContainer>
            <CheckmarkCircle />
          </AnimatedBounceContainer>
          <Typography textAlign="center">
            {isMagicLink
              ? "You have been logged in to the Provide Platform. We're redirecting you now..."
              : "Email Verified"}
          </Typography>
          {!isMagicLink && (
            <ProvideButton
              variant="contained"
              onClick={() => navigate("/dashboard")}
              sx={{ width: 200 }}
            >
              Dashboard
            </ProvideButton>
          )}
        </>
      );
    }

    if (verifyError || authenticateError) {
      return (
        <>
          <AnimatedBounceContainer>
            <RedCrossIcon />
          </AnimatedBounceContainer>
          <Typography color="error" textAlign="center">
            {verifyError?.response?.data.message ||
              authenticateError?.response?.data.message ||
              "Verification failed"}
          </Typography>
          <ProvideButton
            variant="contained"
            onClick={verify}
            sx={{ width: 200 }}
          >
            Try Again
          </ProvideButton>
        </>
      );
    }

    return null;
  };

  return (
    <>
      <LoginWave />
      <RootUnauthScreen>
        <Grid container justifyContent="center">
          <Stack spacing={2} sx={{ zIndex: 1 }}>
            <Typography component="h1" variant="h1">
              {isMagicLink ? "One Time Login" : "Verify Email"}
            </Typography>
            <DropShadowBox>
              <Stack
                spacing={3}
                sx={{
                  zIndex: 1,
                  minHeight: 200,
                  maxWidth: 540,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {renderContent()}
              </Stack>
            </DropShadowBox>
            <DisclaimerText />
          </Stack>
        </Grid>
      </RootUnauthScreen>
    </>
  );
};

export default VerifyEmailScreen;
