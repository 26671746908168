import React, { useState, MouseEvent, useEffect } from "react";
import {
  Box,
  IconButton,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ProvideLogo from "../Icons/ProvideLogo/ProvideLogo";
import iconMap from "./data/navigationRailIconMap";
import {
  navigationDrawerData,
  DrawerMenuItem,
} from "./data/navigationDrawerData";
import useLogout from "../../hooks/useLogout";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { logout } from "../../store/slices/authSlice";
import { RootState } from "../../store/store";

interface NavigationRailProps {
  onNavOpen: (index: number, hasSubItems?: boolean) => void;
  onNavClick: (path: string, index: number) => void;
  onClose: () => void;
  controlledActiveIndex?: number | null;
}

const NavigationRail: React.FC<NavigationRailProps> = ({
  onNavOpen,
  onClose,
  onNavClick,
  controlledActiveIndex,
}) => {
  const theme = useTheme();
  const { handleLogout } = useLogout();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    container,
    iconButton,
    activeBackgroundColor,
    inactiveBackgroundColor,
  } = theme.custom.navigationRail;

  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const settingsPermissions = useAppSelector((state: RootState) =>
    state.settings.userData?.permissions.includes("update-own-users"),
  );

  /*
    Effect to sync the active index with the controlled active index from parent.
    This is to highlight a rail icon if a drawer icon is clicked.

  */
  useEffect(() => {
    if (controlledActiveIndex !== undefined && controlledActiveIndex !== null) {
      setActiveIndex(controlledActiveIndex);
    }
  }, [controlledActiveIndex]);

  /*
    Handle navigation open and determine if the item has sub-items

  */
  const handleNavOpen = (index: number) => {
    const selectedItem = navigationDrawerData[index];
    const hasSubItems = !!selectedItem.items;

    setActiveIndex(index);
    onNavOpen(index, hasSubItems);

    if (!hasSubItems) onClose();
  };

  /*
    Handle click event on the avatar to open the logout menu

  */
  const handleAvatarClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /*
    Close the avatar menu when invoked

  */
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  /*
    Handle logout functionality

  */
  const handleLogoutClick = async () => {
    const result = await handleLogout();

    if (result.success) {
      dispatch(logout());
      handleMenuClose();
      navigate("/");
    }
  };

  /*
    Handle settings route

  */
  const handleSettingsClick = () => {
    handleMenuClose();
    navigate("/settings");
  };

  return (
    <Box sx={container}>
      <Stack direction="column" alignItems="center" spacing={1}>
        <ProvideLogo width={32} height={32} />
        {navigationDrawerData.map((item: DrawerMenuItem, index: number) => (
          <Stack
            key={item.id}
            alignItems="center"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleNavOpen(index);
              onNavClick(item.path!, item.id);
            }}
            onMouseOver={() => onNavOpen(index)}
          >
            <IconButton
              sx={{
                ...iconButton,
                backgroundColor:
                  activeIndex === index
                    ? activeBackgroundColor
                    : inactiveBackgroundColor,
              }}
            >
              {iconMap[item.title]}
            </IconButton>
            <Typography variant="caption">{item.title}</Typography>
          </Stack>
        ))}
      </Stack>
      <Stack alignItems="center">
        <Box>
          <IconButton>
            <NotificationsIcon fontSize="medium" />
          </IconButton>
        </Box>
        <Box>
          <Avatar
            onClick={handleAvatarClick}
            sx={{
              width: 28,
              height: 28,
              fontSize: "0.75rem",
              cursor: "pointer",
            }}
          >
            AM
          </Avatar>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {settingsPermissions ? (
              <MenuItem onClick={handleSettingsClick}>Settings</MenuItem>
            ) : null}
            <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
          </Menu>
        </Box>
      </Stack>
    </Box>
  );
};

export default NavigationRail;
